<template lang="pug">
  .home_wrapper
    .nums
      .num(@click="goOrderPage('waitSend')")
        .c(v-if="!loading.waitSend") {{nums.waitSend}}
        i.iconfont.icon-loading.spin(v-else)
        .name.info 待发货
      .num(@click="goOrderPage('remain3Hour')")
        .c(v-if="!loading.remain3Hour") {{nums.remain3Hour}}
        i.iconfont.icon-loading.spin(v-else)
        .name.info 仅剩3小时
    .nums
      .num(@click="goOrderPage('timeOut')")
        .c(v-if="!loading.timeOut") {{nums.timeOut}}
        i.iconfont.icon-loading.spin(v-else)
        .name.info 已超时
      .num(@click="goOrderPage('sended')")
        .c(v-if="!loading.sended") {{nums.sended}}
        i.iconfont.icon-loading.spin(v-else)
        .name.info 已发货
      .num(@click="goLogistisPage()")
        .c(v-if="!loading.logisticsWarning") {{nums.logisticsWarning}}
        i.iconfont.icon-loading.spin(v-else)
        .name.info 物流预警
    .ops
      .op(@click="goPage('/shop/manage')")
        i.op_icon.iconfont.icon-liebiao
        .op_name 店铺列表
      .op(@click="goPage('/print/template/list')")
        i.op_icon.van-icon.van-icon-setting-o
        .op_name 打印设置
      .op(@click="goPage('/printer')")
        i.op_icon.iconfont.icon-dayinji1
        .op_name 打印机
      .op(@click="showLink")
        i.op_icon.van-icon.van-icon-phone-o
        .op_name 联系客服
</template>

<script>
import {mapGetters} from "vuex";
import utils from "@/common/utils";

export default {
  name: "index",
  data() {
    return {
      nums: {
        waitSend: 0,
        remain3Hour: 0,
        timeOut: 0,
        sended: 0,
        logisticsWarning: 0
      },
      loading: {
        waitSend: false,
        remain3Hour: false,
        timeOut: false,
        sended: false,
        logisticsWarning: false
      },
      minWaitSendTime: null
    }
  },
  computed: {
    ...mapGetters(['currentShop'])
  },
  mounted() {
    this.getMinTimes()
        .then(time => {
          this.getNums();
        })
  },
  methods: {
    goPage(path) {
      this.$router.push({path});
    },
    goOrderPage(type) {
      const now = new Date(),
          nowEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
          before3Day = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2);
      let params;
      if ('waitSend' === type) {
        params = {
          dateRange: [new Date(this.minWaitSendTime) || before3Day, nowEnd],
          timeFiled: 'confirm',
          orderTab: 1
        }
      } else if ('remain3Hour' === type) {
        params = {
          dateRange: '3h',
          timeFiled: 'last_ship',
          orderTab: 1
        }
      } else if ('timeOut' === type) {
        params = {
          dateRange: 'ot',
          timeFiled: 'last_ship',
          orderTab: 1
        }
      } else {
        params = {
          dateRange: 3,
          timeFiled: 'confirm',
          orderTab: 2
        }
      }
      this.$router.push({name: 'order', params})
    },
    goLogistisPage() {
      this.$dialog.alert({
        message: '请在电脑上查看详情',
        theme: 'round-button'
      })
    },
    showLink() {
      const qq = process.env.VUE_APP_QQ;
      this.$dialog.alert({
        title: '联系方式',
        message: 'QQ:' + qq,
        theme: 'round-button'
      }).then(() => {
        utils.copyText(qq);
      })
    },
    getMinTimes() {
      return this.$http.min_times(this.currentShop.ownerId).then(res => {
        if (res.data.length === 0) {
          return null;
        }
        const [ownerId, time] = res.data[0];
        this.minWaitSendTime = time;
        return time;
      })
    },
    getNums() {
      this.loading = {
        waitSend: true,
        remain3Hour: true,
        timeOut: true,
        sended: true,
        logisticsWarning: true
      };
      // 1. 查询最小待发货时间
      const currOwnerId = this.currentShop.ownerId;
      // 2. 组装查询数据请求
      const now = new Date(), nowEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).getTime();
      const startTime = this.minWaitSendTime || new Date(now.getFullYear(), now.getMonth(), now.getDate() - 3).getTime();
      const req = {
        ownerIdList: [currOwnerId],
        printStatus2: null, // 取号状态
        orderStatus: 1, // 订单状态
        printStatus: null, // 打印状态
        refundStatus: [1], // 退款状态，1：无售后或售后关闭，2：售后处理中，3：退款中，4： 退款成功
        // 时间范围
        timeField: 'confirm', // 时间类型
        startTime, // 范围起
        endTime: nowEnd, // 范围止
        minWaitSendTime: startTime
      };
      // 3. 查询
      this.$http.queryOrderNum(req)
          .then(res => {
            this.nums.waitSend = res.data;
          })
          .finally(() => {
            this.loading.waitSend = false;
          });
      req.timeField = 'last_ship';
      req.startTime = now.getTime() - 3 * 60 * 60 * 1000;
      this.$http.queryOrderNum(req)
          .then(res => {
            this.nums.remain3Hour = res.data;
          })
          .finally(() => {
            this.loading.remain3Hour = false;
          });
      req.startTime = now.getTime();
      this.$http.queryOrderNum(req)
          .then(res => {
            this.nums.timeOut = res.data;
          })
          .finally(() => {
            this.loading.timeOut = false;
          });
      req.timeField = 'confirm';
      req.startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7).getTime();
      req.endTime = nowEnd;
      req.minWaitSendTime = req.startTime;
      req.orderStatus = 2;
      this.$http.queryOrderNum(req)
          .then(res => {
            this.nums.sended = res.data;
          })
          .finally(() => {
            this.loading.sended = false;
          });
      this.$http.countExceptionLogistic({
        beginTime: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7).getTime(),
        endTime: nowEnd,
        exceptionType: 0,
        tabs: [0]
      }).then(res => {
        this.nums.logisticsWarning = res.data[0];
      }).finally(() => {
        this.loading.logisticsWarning = false;
      });
    }
  }
}
</script>

<style scoped lang="stylus">
.home_wrapper

  .nums
    background-color white
    display flex
    border-radius 8px
    margin 0 0 1rem

    .num
      flex 1 1 0
      text-align center
      padding .5rem

    .c
      font-size 20px
      font-weight bolder

  .ops
    display flex
    flex-wrap wrap
    margin 0 0 1rem
    border-radius 8px
    background-color white

    .op
      width 25%
      text-align center
      padding .5rem

      .op_icon
        font-size 24px

      .op_name
        font-size 12px
</style>
